import React, { Component } from 'react'
import Cookies from 'js-cookie'
import { jwtDecode } from "jwt-decode"
import { withTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import axios from 'axios'

import Select, { arrayObjToObj } from '../../Components/UI/Select/Select'
import { URL_SEND_EMAIL } from '../../Config/authConfig'
import { getCompanyType } from '../../Utils/generalUtil'
import { setGAEvent } from '../../Utils/googleAnalytics'

import ApiError from '../../Components/UI/Alert/ApiError'
import Layout from '../../Containers/Layout'
import Button from '../../Components/UI/Button/Button'
import Loader from '../../Components/UI/Loader/Loader'
import Modal from '../../Components/UI/Modal/Modal'
import Step from '../../Components/Step/Step'

class Screen1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showErrModal: false,
            showModal:false,
            showLoader: true,
            showLoader1: false,
            redirect: false,
            gotoHome: false,
            modalErr: "",
            companyType: 0,
            companyName: "",
            companyWeb: "",
            companyTradeName: "",
            redirectTo: "",
            creditSafeJWT: "",
            companyList: [],
        }
    }

    componentDidMount() {
        if (Cookies.get("pmx_token") === undefined) {
            this.setState({ redirect: true, gotoHome: true })
        } else {
            setGAEvent("page_view","Busioness Info 1","Step 1")
            const { t } = this.props
            const decodedToken = jwtDecode(Cookies.get("pmx_token"));
            const eml = decodedToken?.emails[0];
            const savedData = Cookies.get("pmx_progress")
            if (Cookies.get("pmx_promoCode") !== undefined && Cookies.get("pmx_promoCode") !== null) {
                axios.get(URL_SEND_EMAIL(eml, Cookies.get("pmx_promoCode") )).then(res => { }).catch(err => { })
            }
            const arrayList = getCompanyType(t)
            if (savedData !== undefined) {
                const jsonObj = JSON.parse(savedData)
                if (jsonObj.page !== '/business-info') {
                    this.setState({
                        redirect: true,
                        redirectTo: (jsonObj.page === undefined) ? "/" : jsonObj.page,
                    })
                } else {
                    this.setState({
                        companyList: arrayList,
                        showLoader: false,
                        redirectTo: (jsonObj.page === undefined) ? "/" : jsonObj.page,
                        companyType: (jsonObj.companyType === undefined) ? "0" : jsonObj.companyType,
                        companyName: (jsonObj.companyName === undefined) ? "" : jsonObj.companyName,
                        companyWeb: (jsonObj.companyWeb === undefined) ? "" : jsonObj.companyWeb,
                        companyTradeName: (jsonObj.companyTradeName === undefined) ? "" : jsonObj.companyTradeName,
                    })
                }
            } else {
                this.setState({ companyList: arrayList, showLoader: false })
            }
        }
    }
    validateForm = () => {
        const { t } = this.props
        if (this.state.companyName.length === 0) {
            this.setState({ showErrModal: true, modalErr: t('ERR_company_name') })
        } else if (this.state.companyType < 1) {
            this.setState({ showErrModal: true, modalErr: t('ERR_company_type') })
        } else {
            const companyInfo = {
                companyType: this.state.companyType,
                companyName: this.state.companyName,
                companyWeb: this.state.companyWeb,
                companyTradeName: this.state.companyTradeName,
                page: '/business-profile'
            }
            Cookies.set("pmx_progress", JSON.stringify(companyInfo), { expires: 365 })
            this.setState({ redirect: true, redirectTo: "/business-profile" })
        }
    }

    render() {
        if (this.state.redirect) {
            if (this.state.gotoHome) {
                return (<Navigate to='/' replace={true} />)
            } else {
                return (<Navigate to={this.state.redirectTo} replace={true} />)
            }
        }
        const { t } = this.props
        let modalData = null
        return (
            <Layout>
                <ApiError isSuccess={false} show={this.state.showErrModal} modalClosed={() => this.setState({ showErrModal: false })} modalType="Alert1" btnName={t('BTN_close')}>
                    <div className="p-3">
                        {this.state.modalErr}
                    </div>
                </ApiError>
                <Modal show={this.state.showModal} modalClosed={() => null} modalType="Modal1">
                    <div className="p-3">
                        <h3 className="modalTitle mb-4 text-center">{t('MODAL_select_company')}</h3>
                        {modalData}
                    </div>
                </Modal>
                {this.state.showLoader ? (
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center H100'>
                        <Loader />
                    </div>
                ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
                        <div className='W100 mt-4'>
                            <Step active={2} step={2} />
                        </div>
                        <div className='W100 pt-2 ps-4 pe-4'>
                            <h1 className='poppins-extrabold'>{t('BP_title1_h1')}</h1>
                            <p className='poppins-regular mt-4 mb-4'>{t('BP_info1_p')}</p>
                            <div>
                                <label className="form-label poppins-regular ">{t('LBL_company_name')}</label>
                                <input type="text" className="form-control" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_company_type')}</label>
                                <Select
                                    styles="form-control"
                                    clicked={(value) => { this.setState({ companyType: value }) }}
                                    selectedItem={this.state.companyType}
                                    options={arrayObjToObj(this.state.companyList)}
                                />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_trading_as')}</label>
                                <input type="text" className="form-control" value={this.state.companyTradeName} onChange={(e) => this.setState({ companyTradeName: e.target.value })} />
                            </div>
                            <div className='mt-4'>
                                <label className="form-label poppins-regular ">{t('LBL_website')}</label>
                                <input type="text" className="form-control" value={this.state.companyWeb} onChange={(e) => this.setState({ companyWeb: e.target.value })} />
                            </div>
                            <Button clicked={() => this.validateForm()} btnType={"mt-5 ButtonLogin"} type="submit">{t('BTN_next')}</Button>
                        </div>
                    </div>
                )}
            </Layout>
        )
    }
}

export default withTranslation()(Screen1)